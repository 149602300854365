import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby'

const GVDTeamPic = () => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "gvd-design-team-min.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 370)
          }
        }
      }
    `}
    render={(data) => {
      return null
      //temporary return null for lvirons while we get team pic
      return (
        <GatsbyImage
          className="shadow-md border-radius mb-4"
          image={getImage(data.fileName.childImageSharp)}
          alt="LVs Iron Works Team Photo"
        />
      )
    }}
  />
)
export default GVDTeamPic
